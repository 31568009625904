import React, { useEffect, useState } from 'react'

// Redux
import { Link, useHistory } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'

// actions
// import images
import logodark from '../../assets/images/logo.png'
import {
    Button,
    Col,
    Container,
    FormGroup,
    Label,
    Row,
    Spinner,
} from 'reactstrap'
import { useAppDispatch } from '../../app/hooks'
import login from '../../services/auth.service'
import { toast } from 'react-toastify'
import { DEFAULT_ERROR_MESSAGE } from '../../helpers/constants'
import { loginSuccess } from '../../store/auth'
import moment from 'moment'

interface ILoginFields {
    username: string
    password: string
}

export default function Login() {
    const initialValues: ILoginFields = { username: '', password: '' }

    const [isCheckingLogin, setIsCheckingLogin] = useState(false)
    const dispatch = useAppDispatch()

    const history = useHistory()

    const formik = useFormik({
        initialValues: initialValues,
        onReset: (values) => {
            values.username = ''
            values.password = ''
        },
        onSubmit: async (values) => {
            try {
                setIsCheckingLogin(true)
                const result = await login(values.username, values.password)

                dispatch(
                    loginSuccess(result.Moderator.Username, result.AccessToken)
                )

                history.push('/dashboard')
            } catch (e) {
                toast.error(DEFAULT_ERROR_MESSAGE)
            } finally {
                setIsCheckingLogin(false)
            }
        },
        validate: (values) => {
            const errors: any = {}

            if (!values.username || values.username === '') {
                errors.username = 'Bitte geben Sie einen Benutzernamen ein'
            }

            if (!values.password || values.password === '') {
                errors.password = 'Bitte geben Sie ein Passwort ein'
            }

            return errors
        },
    })

    useEffect(() => {
        formik.setSubmitting(isCheckingLogin)
    }, [isCheckingLogin])

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/">
                    <i className="mdi mdi-home-variant h2 text-white" />
                </Link>
            </div>

            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link
                                                            to="/"
                                                            className="logo"
                                                        >
                                                            <img
                                                                src={logodark}
                                                                height="100"
                                                                alt="logo"
                                                            />
                                                        </Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">
                                                        Willkommen zurück!
                                                    </h4>
                                                    <p className="text-muted">
                                                        Melden Sie sich an um
                                                        zur Moderation zu
                                                        gelangen.
                                                    </p>
                                                </div>

                                                <div className="p-2 mt-5">
                                                    <FormikProvider
                                                        value={formik}
                                                    >
                                                        <form
                                                            className={
                                                                'form-horizontal'
                                                            }
                                                            onSubmit={
                                                                formik.handleSubmit
                                                            }
                                                        >
                                                            <FormGroup className="auth-form-group-custom mb-4">
                                                                <i className="ri-user-2-line auti-custom-input-icon" />
                                                                <Label htmlFor="username">
                                                                    Benutzername
                                                                </Label>
                                                                <input
                                                                    type={
                                                                        'text'
                                                                    }
                                                                    className={
                                                                        'form-control' +
                                                                        (formik
                                                                            .errors
                                                                            .username &&
                                                                        formik
                                                                            .touched
                                                                            .username
                                                                            ? ' is-invalid'
                                                                            : '')
                                                                    }
                                                                    id={
                                                                        'username'
                                                                    }
                                                                    name={
                                                                        'username'
                                                                    }
                                                                    placeholder={
                                                                        'Benutzername eingeben'
                                                                    }
                                                                    onChange={
                                                                        formik.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formik.handleBlur
                                                                    }
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .username
                                                                    }
                                                                />
                                                                {formik.errors
                                                                    .username &&
                                                                formik.touched
                                                                    .username ? (
                                                                    <div
                                                                        className={
                                                                            'invalid-feedback'
                                                                        }
                                                                    >
                                                                        {
                                                                            formik
                                                                                .errors
                                                                                .username
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </FormGroup>

                                                            <FormGroup className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon" />
                                                                <Label htmlFor="password">
                                                                    Passwort
                                                                </Label>
                                                                <input
                                                                    type={
                                                                        'password'
                                                                    }
                                                                    className={
                                                                        'form-control' +
                                                                        (formik
                                                                            .errors
                                                                            .password &&
                                                                        formik
                                                                            .touched
                                                                            .password
                                                                            ? ' is-invalid'
                                                                            : '')
                                                                    }
                                                                    id={
                                                                        'password'
                                                                    }
                                                                    name={
                                                                        'password'
                                                                    }
                                                                    placeholder={
                                                                        'Passwort eingeben'
                                                                    }
                                                                    onChange={
                                                                        formik.handleChange
                                                                    }
                                                                    onBlur={
                                                                        formik.handleBlur
                                                                    }
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .password
                                                                    }
                                                                />
                                                                {formik.errors
                                                                    .password &&
                                                                formik.touched
                                                                    .password ? (
                                                                    <div
                                                                        className={
                                                                            'invalid-feedback'
                                                                        }
                                                                    >
                                                                        {
                                                                            formik
                                                                                .errors
                                                                                .password
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </FormGroup>

                                                            <div className="mt-4 text-center">
                                                                <Button
                                                                    color={
                                                                        'primary'
                                                                    }
                                                                    disabled={
                                                                        isCheckingLogin
                                                                    }
                                                                    className={
                                                                        'w-md waves-effect waves-light'
                                                                    }
                                                                    type={
                                                                        'submit'
                                                                    }
                                                                >
                                                                    {isCheckingLogin ? (
                                                                        <div
                                                                            className={
                                                                                'd-flex align-items-center'
                                                                            }
                                                                        >
                                                                            <Spinner
                                                                                size={
                                                                                    'sm'
                                                                                }
                                                                            />
                                                                            <div
                                                                                className={
                                                                                    'ms-2'
                                                                                }
                                                                            >
                                                                                Überprüfe
                                                                                Login
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            Anmelden
                                                                        </>
                                                                    )}
                                                                </Button>
                                                            </div>
                                                        </form>
                                                    </FormikProvider>
                                                </div>

                                                <div className="mt-2 text-center">
                                                    <p>
                                                        © {moment().year()}{' '}
                                                        loveSystem
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg position-relative">
                                <div className="bg-overlay" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
