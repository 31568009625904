import React, {useContext, useState} from "react";
import {DialogContext} from "../../context/dialog.context";
import ModeratorDialogs from "../Moderator/ModeratorDialogs";

export default function DialogToolbar() {

  const context = useContext(DialogContext)

  const [isModeratorDialogsOpen, setIsModeratorDialogsOpen] = useState(false)

  return (
      <>
        <div className={"w-100 d-flex flex-col flex-wrap justify-content-center content-center gap-4"}>
          <button className={"btn btn-outline-primary"} onClick={() => {
            return context?.transferDialog();
          }}>Übergeben
          </button>

          <button className={"btn btn-outline-primary"} onClick={async () => {
            await context?.finishDialog()
          }}>Abschließen
          </button>

          <button className={"btn btn-outline-primary"} onClick={async () => {
            await context?.ignoreDialog()
          }}>Aussetzen
          </button>

          <button className={"btn btn-outline-primary"} onClick={async () => {
            await context?.switchProfileDialog()
          }}>Profil
            wechseln
          </button>

          <button className={"btn btn-outline-primary"}
                  onClick={() => setIsModeratorDialogsOpen(!isModeratorDialogsOpen)}>Mod Übersicht
          </button>
        </div>

        <ModeratorDialogs isOpen={isModeratorDialogsOpen}/>
      </>
  )
}
