import React, { ComponentType } from 'react'
import { Redirect } from 'react-router-dom'

// Authentication related pages
import Login from '../pages/Authentication/Login'

// Dashboard
import StarterPage from '../pages/Dashboard'
import QueuePage from '../pages/Queue'

interface IRoute {
    path: string
    component: ComponentType
    exact?: boolean
}

const authProtectedRoutes: IRoute[] = [
    { path: '/dashboard', component: StarterPage },

    { path: '/queue', component: QueuePage },

    // this route should be at the end of all other routes
    { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes: IRoute[] = [{ path: '/login', component: Login }]

export { authProtectedRoutes, publicRoutes }
