import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay-ts";
import {INotification} from "../../../models/notification";
import {getLatestKisses} from "../../../services/notification.service";
import {DialogContext} from "../../../context/dialog.context";

export default function DialogKissesConversation() {

  const [isLoading, setIsLoading] = useState(false)
  const [kisses, setKisses] = useState<INotification[]>([])

  const context = useContext(DialogContext)

  useEffect(() => {

    let cancel = false

    if (!context?.activeDialog?.UserId || !context?.activeDialog?.FakeId) {
      setKisses([])
      return
    }


    const loadKisses = async () => {

      setIsLoading(true)

      const kisses = await getLatestKisses(context?.activeDialog?.UserId!, context?.activeDialog?.FakeId!)

      if (cancel) return

      setKisses(kisses)
      setIsLoading(false)
    }

    loadKisses()

    return () => {
      cancel = true
    }


  }, [context?.activeDialog?.UserId, context?.activeDialog?.FakeId])

  return (
      <LoadingOverlay spinner active={isLoading}>
        <div className={"d-flex flex-column justify-content-stretch mt-2"}>

          {kisses.length <= 0 ? "Keine Küsse vorhanden" : null}
          {kisses.map((kiss) => {

            return (
                <div key={kiss.NotificationId}>
                  <div className={"d-flex flex-row mt-2"}>
                    <div className={"d-flex flex-column flex-grow-1"}>
                      <strong>{kiss.FromUserId === context?.activeDialog?.FakeId ? "Wir" : "Kunde"}</strong>
                      <small>{kiss.Message}</small>
                    </div>
                    <div className={"ml-auto"}>
                      <small>{moment(kiss.DateCreate).format("dddd, DD.MMMM YYYY HH:mm")}</small>
                    </div>
                  </div>
                  <hr className={"w-100"}/>
                </div>
            )

          })}

        </div>
      </LoadingOverlay>
  )
}
