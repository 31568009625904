import React, {useEffect, useState} from "react"
import axios from "axios";
import {Spinner} from "reactstrap";
import {getPresentPictureUrl} from "../../../services/present.service";

interface IPresentOptions {
  presentId: number
  height?: number
}

export default function PresentPicture(opt: IPresentOptions) {

  const [presentUrl, setPresentUrl] = useState<string>()

  useEffect(() => {

    let cancelToken = axios.CancelToken.source()

    const loadPresentImage = async function (presentId: number) {
      const imageUrl = await getPresentPictureUrl(presentId, cancelToken.token)

      setPresentUrl(imageUrl)
    }

    loadPresentImage(opt.presentId)

    return () => {
      cancelToken.cancel("Component unmounted.")
    }
  }, [opt.presentId])

  if (presentUrl) {
    return (
        <img src={presentUrl} height={opt.height ?? 80} alt={"Geschenk Bild"}/>
    )
  }

  return (
      <Spinner size={"sm"}/>
  )
}
