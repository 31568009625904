import React, { ComponentType } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAppSelector } from '../app/hooks'
import HorizontalLayout from '../components/HorizontalLayout'
import NonAuthLayout from '../components/NonAuthLayout'

export type AppRouteProps = {
    isProtected: boolean
    authenticationPath: string
    authLayout: boolean
    component: ComponentType
} & RouteProps

export default function AppRoute({
    isProtected,
    authenticationPath,
    component,
    authLayout,
    ...routeProps
}: AppRouteProps) {
    const isLoggedIn = useAppSelector((state) => state.authReducer.isLoggedIn)
    const token = useAppSelector((state) => state.authReducer.token)

    const Layout = authLayout ? NonAuthLayout : HorizontalLayout
    const Comp = component

    console.log('navigation to:' + routeProps.path)

    if (isProtected && (!isLoggedIn || !token)) {
        return <Redirect to={{ pathname: authenticationPath }} />
    }

    return (
        <Route
            {...routeProps}
            render={(props) => {
                // @ts-ignore
                return <Comp {...props} />
            }}
        />
    )
}
