import React, {useContext, useEffect, useState} from "react"
import {Card, CardBody, CardHeader, Collapse, Form} from "reactstrap";
import {toast} from "react-toastify";
import {Field, FormikProvider, useFormik} from "formik";
import LoadingButton from "../../components/Common/LoadingButton";
import {sendMessage} from "../../services/message.service";
import {DialogMessageInfo} from "../../models/dialog";
import styled from "styled-components";
import {DialogContext} from "../../context/dialog.context";

type SendMessageOptions = {
  info: DialogMessageInfo
}

const ClearMessage = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;

    margin-left: 10px;
    margin-bottom: 10px;
`

const CharacterCount = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;

    margin-right: 10px;
    margin-bottom: 10px;
`

export default function SendMessage(opt: SendMessageOptions) {

  const initialValues: {
    message?: string
  } = {
    message: undefined
  }

  const [showTemplates, setShowTemplates] = useState(false)
  const [messageImage, setMessageImage] = useState<File>()
  const [characterCount, setCharacterCount] = useState<number>(0)

  const context = useContext(DialogContext)

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    validateOnMount: true,
    onReset: values => {
      values.message = undefined
    },
    onSubmit: async (values) => {

      if (!context?.activeDialog) return

      try {
        await sendMessage(context.activeDialog.FakeId, context.activeDialog.UserId, values.message ?? null, messageImage ?? null)

        toast.success("Die Nachricht wurde erfolgreich versandt.")
        await context.finishDialog()
      } catch (err) {
        toast.error("Beim Versenden der Nachricht ist ein Fehler aufgetreten.")
      }

    },
    validate: values => {
      const errors: any = {}

      if (!values.message && !messageImage) {
        errors.message = "Sie müssen entweder ein Bild auswählen oder eine Nachricht eingeben."
      }

      return errors
    }
  })


  useEffect(() => {
    setCharacterCount(formik.values.message?.length ?? 0)
  }, [formik.values.message])


  return (
      <FormikProvider value={formik}>
        <Form className={"form-horizontal"} onSubmit={formik.handleSubmit}>


          <div className={'form-group position-relative'}>
            <label className={'form-label'}>Nachricht verfassen</label>
            <ClearMessage>
              <a
                  href={'#'}
                  className={'text-danger'}
                  onClick={() => formik.setFieldValue('message', '')}
              >
                <i className={'ri-delete-bin-line'}></i>
              </a>
            </ClearMessage>
            <Field type={"text"} as={"textarea"} rows={5} name={"message"}
                   placeholder={"Nachricht eingeben"}
                   className={"form-control" + (formik.errors.message && formik.touched.message ? ' is-invalid' : "")}/>

            <CharacterCount>Zeichenanzahl: {characterCount}</CharacterCount>
          </div>

          <div
              className={
                'my-3 justify-content-center fs-5 text-uppercase d-flex flex-column align-items-center'
              }
          >
            <Card className={'w-100 text-center'}>
              <CardHeader>
                <div
                    className={'text-dark fw-bolder'}
                    onClick={() => setShowTemplates(!showTemplates)}
                >
                  Vordefinierten Text{' '}
                  {showTemplates ? 'schließen' : 'auswählen'}
                </div>
              </CardHeader>
            </Card>

            <Collapse
                isOpen={showTemplates}
                className={'mt-3 text-capitalize'}
            >
              {opt.info?.Templates.map((tmpl) => {
                return (
                    <Card key={tmpl.DialogTemplateId}>
                      <CardHeader color={'bg-black'}>
                        <strong>{tmpl.Description}</strong>
                      </CardHeader>
                      <CardBody>
                        {tmpl.Texts.map((text) => {
                          return (
                              <div
                                  className={
                                    'p-4 bg-light my-3 fs-6'
                                  }
                                  onClick={(e) => {
                                    formik.setFieldValue('message', text.Text)
                                    setShowTemplates(false)
                                  }}
                                  key={text.DialogTemplateTextId}
                                  style={{cursor: 'pointer'}}
                              >
                                {text.Text}
                              </div>
                          )
                        })}
                      </CardBody>
                    </Card>
                )
              })}
            </Collapse>
          </div>

          <div className={'form-group mt-3'}>
            <label className={'form-label'}>Bild anhängen</label>
            <input type={"file"}
                   className={
                       "form-control" + (formik.errors.message && formik.touched.message ? " is-invalid" : "")
                   }
                   id={"file"}
                   name={"file"}
                   placeholder={"Bild auswählen"}
                   accept="image/png, image/gif, image/jpeg"
                   onChange={(ev) => {

                     if (ev.currentTarget && ev.currentTarget.files && ev.currentTarget.files.length > 0) {
                       setMessageImage(ev.currentTarget.files[0])
                     } else {
                       setMessageImage(undefined)
                     }

                   }}
                   onBlur={formik.handleBlur}
            />
            {formik.errors.message && formik.touched.message ?
                <div
                    className={"invalid-feedback"}>{formik.errors.message}</div> : <></>}
          </div>

          <div
              className={
                'mt-4 d-flex justify-content-around align-items-center'
              }
          >
            <LoadingButton
                isLoading={false}
                caption={'Nachricht absenden'}
                isSubmit={true}
                className={'d-flex align-items-center'}
                disabled={!formik.isValid || formik.isSubmitting}
                color={'primary'}
            >
              <i className="ri-send-plane-line me-2"></i> Nachricht
              versenden
            </LoadingButton>
          </div>
        </Form>
      </FormikProvider>
  )
}
