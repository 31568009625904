import {
    configureStore,
    ThunkAction,
    Action,
    compose,
    combineReducers,
} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import authReducer from '../store/auth'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
    key: 'lovesystemmod',
    storage,
    debug: true,
}

const rootReducer = combineReducers({
    authReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware],
})

const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

export function create() {
    return { store, persistor }
}
