import React, {useEffect} from 'react'

import {Link, useLocation} from 'react-router-dom'

// reactstrap
import {Button,} from 'reactstrap'

// Import menuDropdown
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'

//Import Logos
import logo from '../../assets/images/favicon.png'

// Redux Store

interface FullScreenDocument extends Document {
  documentElement: FullScreenDocumentElement
  mozFullScreenElement?: Element
  msFullscreenElement?: Element
  webkitFullscreenElement?: Element
  msExitFullscreen?: () => void
  mozCancelFullScreen?: () => void
  cancelFullScreen?: () => void
  webkitCancelFullScreen?: () => void
  webkitExitFullscreen?: () => void
}

interface FullScreenDocumentElement extends HTMLElement {
  msRequestFullscreen?: () => void
  mozRequestFullScreen?: () => void
  webkitRequestFullscreen?: () => void
}

type HeaderOptions = {
  toggleMenu: () => void
}

export default function Header(opt: HeaderOptions) {
  const document = window.document as FullScreenDocument

  const location = useLocation()

  useEffect(() => {

    opt.toggleMenu()

  }, [location])

  /**
   * Toggle sidebar
   */
  const toggleMenu = function () {
    opt.toggleMenu()
    // this.props.toggleMenuCallback();
  }

  const toggleFullscreen = function () {
    if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen()
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <img src={logo} style={{height: 34, width: 'auto'}}/>
                </Link>

                <Link to="/" className="logo logo-light ">
                  <img src={logo} style={{height: 34, width: 'auto'}}/>
                </Link>
              </div>

              <Button
                  color="none"
                  type="button"
                  size="sm"
                  onClick={toggleMenu}
                  className="px-3 font-size-24 d-lg-none header-item"
                  data-toggle="collapse"
                  data-target="#topnav-menu-content"
              >
                <i className="ri-menu-2-line align-middle"></i>
              </Button>
            </div>

            <div className="d-flex">
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <Button
                    type="button"
                    color="none"
                    onClick={toggleFullscreen}
                    className="header-item noti-icon waves-effect"
                    data-toggle="fullscreen"
                >
                  <i className="ri-fullscreen-line"></i>
                </Button>
              </div>

              <ProfileMenu/>
            </div>
          </div>
        </header>
      </React.Fragment>
  )
}
