import {Card, CardBody, CardHeader, Collapse} from 'reactstrap'
import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import {DEFAULT_ERROR_MESSAGE} from '../../helpers/constants'
import {toast} from 'react-toastify'
import {OpenDialogCount} from '../../models/dialog'
import {getOpenDialogCount} from '../../services/dialog.service'
import {DialogContext} from "../../context/dialog.context";
import LoadingOverlay from "react-loading-overlay-ts";

export default function QueueConfiguration() {
  const [dialogType, setDialogType] = useState<string>()

  const [isConfigOpen, setIsConfigOpen] = useState(true)

  const [isNormalChecked, setIsNormalChecked] = useState(false)

  const [openDialogCount, setOpenDialogCount] = useState<OpenDialogCount>()

  const context = useContext(DialogContext)
  const [isLoadingInitially, setIsLoadingInitially] = useState(true)

  useEffect(() => {
    context?.setType(dialogType)
  }, [dialogType])

  useEffect(() => {
    if (isNormalChecked) {
      setDialogType('normal')
    }
  }, [isNormalChecked])

  useEffect(() => {
    const interval = setInterval(async () => await loadDialogCount(), 10000)

    loadDialogCount()

    return () => {
      clearInterval(interval)
    }
  }, [])

  const loadDialogCount = async () => {
    try {
      const count = await getOpenDialogCount()

      setOpenDialogCount(count)

      if (isLoadingInitially) {
        setIsLoadingInitially(false)
      }
    } catch (e) {
      toast.error(DEFAULT_ERROR_MESSAGE)
    }
  }

  const onNormalChange = (e: ChangeEvent<HTMLInputElement>) => {
    // selected
    if (e.target.checked) {
      setIsNormalChecked(true)
    }
  }

  const onDialogTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setIsNormalChecked(false)
    setDialogType(e.target.value)
    setIsConfigOpen(false)
  }

  return (
      <Card className={'w-100'}>
        <CardHeader className={'text-center bg-info text-white'}>
          <a
              href={'#'}
              className={'text-white fw-bolder fs-5 text-uppercase'}
              onClick={() => setIsConfigOpen(!isConfigOpen)}
          >
            Konfiguration{" "}{context?.type ? `(${context.type})` : null}
          </a>
        </CardHeader>
        <Collapse isOpen={isConfigOpen}>

          <LoadingOverlay spinner active={isLoadingInitially} className={"w-100 p-2"}>
            <CardBody
                className={
                  'd-flex justify-content-center flex-column flex-md-row justify-items-center justify-content-around'
                }
            >
              <div className="form-check align-self-center">
                <input
                    className={'form-check-input'}
                    type={'radio'}
                    value={'1'}
                    checked={isNormalChecked}
                    onChange={onNormalChange}
                    id={'normal-radio-button'}
                />
                <label
                    className="form-check-label fs-6 font-weight-lighter ms-2"
                    htmlFor={'normal-radio-button'}
                >
                  Normal ({openDialogCount?.Normal ?? 0})
                </label>
              </div>
              <div className={'p-3  align-self-center'}>
                <strong>oder Typ auswählen</strong>
              </div>
              <div
                  className={
                    'd-flex flex-row flex-wrap align-self-center col-12 col-md-6'
                  }
              >
                <select
                    className={'form-control'}
                    value={dialogType}
                    onChange={onDialogTypeChange}
                >
                  <option>Bitte auswählen</option>
                  <option value={'newcustomer'}>
                    Neukunde ({openDialogCount?.Normal ?? 0})
                  </option>
                  <option value={'asa_part_time'}>
                    ASA Teilzeit 3h (
                    {openDialogCount?.AsaPartTime ?? 0})
                  </option>
                  <option value={'asa'}>
                    ASA ({openDialogCount?.Asa ?? 0})
                  </option>
                  <option value={'asa_gay_men'}>
                    ASA Mann Schwul (
                    {openDialogCount?.AsaGayMen ?? 0})
                  </option>
                  <option value={'asa_straight_men'}>
                    ASA Mann Normal (
                    {openDialogCount?.AsaStraightMen ?? 0})
                  </option>
                  <option value={'asa_gay_women'}>
                    ASA Frauen Schwul (
                    {openDialogCount?.AsaGayWomen ?? 0})
                  </option>
                  <option value={'asa_straight_women'}>
                    ASA Frauen Normal (
                    {openDialogCount?.AsaStraightWomen ?? 0})
                  </option>
                  <option value={'asa_morning'}>
                    ASA Morgen ({openDialogCount?.AsaMorning ?? 0})
                  </option>
                  <option value={'event'}>
                    Event ({openDialogCount?.Event ?? 0})
                  </option>
                  <option value={'swiper'}>
                    Swiper ({openDialogCount?.Swiper ?? 0})
                  </option>
                </select>
              </div>
            </CardBody>
          </LoadingOverlay>
        </Collapse>
      </Card>
  )
}
