import React, {useState} from 'react'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from 'reactstrap'

// users
import avatar from '../../../assets/images/users/profile_small.jpg'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {logout} from '../../../store/auth'

export function ProfileMenu() {
  const [menu, setMenu] = useState(false)
  const username = useAppSelector((state) => state.authReducer.username)

  const dispatch = useAppDispatch()

  const toggle = function () {
    setMenu(!menu)
  }
  return (
      <React.Fragment>
        <Dropdown
            isOpen={menu}
            toggle={toggle}
            className="user-dropdown flex w-full place-content-center place-items-center"
        >
          <DropdownToggle
              tag="button"
              className="btn header-item waves-effect mx-auto text-center"
              id="page-header-user-dropdown"
          >
            <img
                className="rounded-circle header-profile-user"
                src={avatar}
                alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ms-1 text-transform">
                        {username}
                    </span>
            <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"/>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem href="#">
              <i className="ri-user-line align-middle me-1"/> Profil
            </DropdownItem>
            <DropdownItem
                className="text-danger"
                href="/logout"
                onClick={() => {
                  dispatch(logout())
                }}
            >
              <i className="ri-shut-down-line align-middle me-1 text-danger"/>
              Abmelden
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
  )
}

export default ProfileMenu
