import {Modal, ModalBody, ModalHeader} from "reactstrap";
import React, {Fragment, useContext, useState} from "react";
import {DialogContext} from "../../context/dialog.context";
import LoadingOverlay from "react-loading-overlay-ts";
import {createPortal} from "react-dom";
import {switchProfile} from "../../services/dialog.service";
import {toast} from "react-toastify";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {User} from "../../models/user";
import axios, {CancelTokenSource} from "axios";
import {findProfiles, getProfilePictureUrl} from "../../services/user.service";
import UserName from "../User/UserName";

export type DialogSwitchProfileModalOptions = {
  isOpen: boolean
  close: (transferred: boolean) => void
}

export default function DialogSwitchProfileModal(opt: DialogSwitchProfileModalOptions) {

  const context = useContext(DialogContext)

  const [profile, setProfile] = useState<User>()
  const [profiles, setProfiles] = useState<User[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const onSwitchUser = async () => {

    if (!context || !context.activeDialog || !profile) return

    try {
      setIsLoading(true)
      await switchProfile(context.activeDialog.DialogId, profile.UserId)
      opt.close(true)

      toast.success("Das Profil wurde erfolgreich gewechselt!")
    } catch (e) {
      toast.error("Beim Wechseln des Profils ist ein Fehler aufgetreten.")
    } finally {
      setIsLoading(false)
    }
  }

  let cancelToken: CancelTokenSource | undefined;

  const handleSearch = async (query: string) => {
    if (cancelToken) {
      setProfiles([])
    }

    cancelToken = axios.CancelToken.source()
    setIsLoading(true)

    if (!query) return

    try {

      const profiles = await findProfiles(query, cancelToken?.token)

      setProfiles(profiles)
    } catch (err) {
      setProfiles([])
    } finally {
      setIsLoading(false)
    }


  }

  return createPortal(
      <Modal isOpen={opt.isOpen} toggle={() => opt.close(false)}>
        <ModalHeader>
          Dialog übergeben
        </ModalHeader>
        <ModalBody>
          <LoadingOverlay active={isLoading} spinner>
            <div className={"flex flex-row"}>
              <label className={"text-black"}>
                Profil auswählen
              </label>

              <AsyncTypeahead isLoading={isLoading} onSearch={handleSearch}
                              options={profiles}
                              multiple={false}
                              placeholder={"Nach Profil suchen ..."} filterBy={() => true}
                              id={"profile"}
                              onChange={(selected) => {
                                if (selected.length <= 0) return

                                setProfile(selected[0])
                              }}
                              labelKey={(customer) => `${customer.Username} (${customer.Email}) - ${customer.Gender === "female" ? "weiblich" : "männlich"}`}
                              minLength={4}
                              renderMenuItemChildren={(option, props) => {


                                return (
                                    <Fragment>
                                      <div className={"d-flex flex-wrap"}>
                                        <div>
                                          <img src={getProfilePictureUrl(option.UserId)} width={20}
                                               style={{borderRadius: "5px"}}/>
                                        </div>
                                        <div className={"ml-2"}>
                                          <UserName userId={option.UserId}/>
                                        </div>
                                        <div className={"ml-2"}>
                                          ({option.Email})
                                        </div>
                                        <div className={"ml-2"}>
                                          {option.Gender === "female" ? "weiblich" : "männlich"}
                                        </div>
                                      </div>
                                    </Fragment>
                                );
                              }}/>

              <button
                  className={`btn btn-primary mt-3 w-100 ${profile ? "" : "disabled"}`}
                  disabled={profile === undefined}
                  onClick={async () => await onSwitchUser()}>
                Wechseln
              </button>
              <button className={"btn btn-danger text-white mt-3 w-100"}
                      onClick={() => opt.close(false)}>
                Abbrechen
              </button>
            </div>
          </LoadingOverlay>
        </ModalBody>
      </Modal>, document.body
  )
}
