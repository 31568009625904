import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'
import { DEFAULT_ERROR_MESSAGE } from '../../helpers/constants'
import { getNotes, updateNotes } from '../../services/user.service'
import LoadingOverlay from 'react-loading-overlay-ts'

declare type DialogNotesOptions = {
    userId?: number
    partnerId?: number
}

export default function DialogNotes(opt: DialogNotesOptions) {
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdatingNotes, setIsUpdatingNotes] = useState(false)
    const [dialogNotes, setDialogNotes] = useState<string>()

    const loadDialogNotes = async () => {
        setIsLoading(true)

        try {
            const notes = await getNotes(opt.userId!, opt.partnerId!)

            setIsLoading(false)
            setDialogNotes(notes)
        } catch (e) {
            toast.error(DEFAULT_ERROR_MESSAGE)
        }
    }

    const onUpdateNotes = async () => {
        setIsUpdatingNotes(true)

        try {
            await updateNotes(opt.userId!, opt.partnerId!, dialogNotes!)

            setIsUpdatingNotes(false)
        } catch (e) {
            toast.error(DEFAULT_ERROR_MESSAGE)
        }
    }

    useEffect(() => {
        if (!dialogNotes || isLoading) return

        const timeout = setTimeout(onUpdateNotes, 1000)

        return () => clearTimeout(timeout)
    }, [dialogNotes])

    useEffect(() => {
        if (!opt.userId || !opt.partnerId) return

        loadDialogNotes()
    }, [opt.userId, opt.partnerId])

    if (isLoading) {
        return (
            <>
                <Spinner className={'me-2'} color={'dark'} size={'sm'} />
                <strong>Lade Notizen</strong>
            </>
        )
    }

    return (
        <>
            <LoadingOverlay active={isUpdatingNotes} spinner>
                <textarea
                    rows={30}
                    className={'form-control w-100'}
                    value={dialogNotes}
                    onChange={(e) => setDialogNotes(e.target.value)}
                    style={{ fontSize: '0.9em' }}
                ></textarea>
            </LoadingOverlay>
        </>
    )
}
