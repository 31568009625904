import React, {useEffect, useState} from 'react'

// Other Layout related Component
import Navbar from './Navbar'
import Header from './Header'
import Footer from './Footer'
import {useHistory} from 'react-router-dom'
import {useAppSelector} from '../../app/hooks'

export default function Layout(props: React.PropsWithChildren<any>) {
  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const history = useHistory()

  const isLoggedIn = useAppSelector((state) => state.authReducer.isLoggedIn)

  useEffect(() => {
    changeBodyAttribute('data-topbar', 'dark')
    changeBodyAttribute('data-layout-size', 'fluid')
    changeBodyAttribute('data-layout', 'horizontal')

    // Scrollto 0,0
    window.scrollTo(0, 0)

    const title = history.location.pathname
    let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    document.title = currentage + ' | loveSystem Moderation'
  }, [])

  const openMenu = (e: any) => {
    setIsMenuOpened(!isMenuOpened)
  }

  const changeBodyAttribute = (attribute: any, value: any) => {
    if (document.body) document.body.setAttribute(attribute, value)
    return true
  }

  return (
      <React.Fragment>
        <div id="layout-wrapper">
          {isLoggedIn ? (
              <>
                <Header toggleMenu={() => setIsMenuOpened(!isMenuOpened)}/>
                <Navbar menuOpen={isMenuOpened}/>
              </>
          ) : (
              <></>
          )}

          <div className="main-content">
            {props.children}
            <Footer/>
          </div>
        </div>
      </React.Fragment>
  )
}
