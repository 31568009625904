import axios, {CancelToken} from 'axios'
import environment from '../helpers/environment'
import {User, UserInformation, UserInterest} from '../models/user'

export async function getUserById(id: number, cancelToken?: CancelToken): Promise<User> {
  return (await axios.get<User>(`${environment.API_URL}/user/${id}`, {
    cancelToken
  })).data
}

export async function findProfiles(username: string, cancelToken?: CancelToken): Promise<User[]> {
  return (await axios.get<User[]>(`${environment.API_URL}/user/search/${username}`, {
    cancelToken
  })).data
}

export async function getProfilePicture(id: number): Promise<string> {
  return (
      await axios.get<string>(
          `${environment.API_URL}/user/${id}/profile-picture`
      )
  ).data
}

export function getProfilePictureUrl(id: number): string {
  return `${environment.API_URL}/user/${id}/profile-picture`
}

export async function getCoinCount(id: number): Promise<string> {
  return (await axios.get<string>(`${environment.API_URL}/user/${id}/coins`))
      .data
}

export async function getNotes(id: number, partnerId: number): Promise<string> {
  return (
      await axios.get<string>(
          `${environment.API_URL}/user/${id}/notes/${partnerId}`
      )
  ).data
}

export async function updateNotes(
    id: number,
    partnerId: number,
    notes: string
): Promise<void> {
  await axios.post<string>(
      `${environment.API_URL}/user/${id}/notes/${partnerId}`,
      {notes: notes}
  )
}

export async function isUserVerified(id: number): Promise<boolean> {
  return (
      await axios.get<boolean>(`${environment.API_URL}/user/${id}/verified`)
  ).data
}

export async function getInfo(id: number): Promise<UserInformation> {
  return (
      await axios.get<UserInformation>(
          `${environment.API_URL}/user/${id}/info`
      )
  ).data
}

export async function getInterest(id: number): Promise<UserInterest> {
  return (
      await axios.get<UserInterest>(
          `${environment.API_URL}/user/${id}/interest`
      )
  ).data
}

export async function hasChargedAccount(id: number): Promise<boolean> {
  return (
      await axios.get<boolean>(
          `${environment.API_URL}/user/${id}/has-charged`
      )
  ).data
}
