import axios, {AxiosError} from 'axios'
import environment from '../helpers/environment'
import {DialogInfo, DialogMessageInfo, OpenDialogCount, PendingDialog,} from '../models/dialog'

export async function getOpenDialogCount(): Promise<OpenDialogCount> {
    return (
        await axios.get<OpenDialogCount>(
            `${environment.API_URL}/dialog/open-count`
        )
    ).data
}

export async function getPendingDialog(type: string): Promise<PendingDialog | null> {

    try {
        const pendingDialogResponse =
            await axios.get<PendingDialog>(
                `${environment.API_URL}/dialog/pending/${type}`)

        return pendingDialogResponse.data

    } catch (e) {
        if(e instanceof AxiosError) {
            if(e.response?.status === 400) {
                return null
            } else {
                throw e
            }
        }
        return null
    }
}

export async function getDialogInfo(id: number): Promise<DialogInfo> {
    return (
        await axios.get<DialogInfo>(`${environment.API_URL}/dialog/info/${id}`)
    ).data
}

export async function switchProfile(id: number, profileId: number): Promise<void> {
    await axios.post(`${environment.API_URL}/dialog/${id}/switch-profile/${profileId}`)
}


export async function transferDialog(id: number, moderatorId: number): Promise<void> {
    await axios.post(`${environment.API_URL}/dialog/${id}/transfer/${moderatorId}`)
}

export async function ignoreDialog(id: number, minutes: number): Promise<void> {
    await axios.post(`${environment.API_URL}/dialog/${id}/ignore/${minutes}`)
}


export async function getDialogMessageInfo(
    id: number,
    userId: number,
    fakeId: number
): Promise<DialogMessageInfo> {
    return (
        await axios.get<DialogMessageInfo>(
            `${environment.API_URL}/dialog/message-info/${id}/${userId}/${fakeId}`
        )
    ).data
}
