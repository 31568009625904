import React, {useEffect, useState} from "react";
import {getPendingDialog} from "../services/dialog.service";
import {toast} from "react-toastify";
import {DEFAULT_ERROR_MESSAGE} from "../helpers/constants";
import {PendingDialog} from "../models/dialog";
import DialogTransferModal from "../components/Dialog/DialogTransferModal";
import {finishDialog} from "../services/message.service";
import DialogSwitchProfileModal from "../components/Dialog/DialogSwitchProfileModal";
import DialogIgnoreModal from "../components/Dialog/DialogIgnoreModal";

export type DialogContextType = {
    activeDialog?: PendingDialog

    isLoading: boolean

    type: string | undefined
    setType: (type: string | undefined) => void
    finishDialog: () => Promise<void>
    loadDialog: () => Promise<void> | void
    transferDialog: () => void
    switchProfileDialog: () => void
    ignoreDialog: () => void
}

export const DialogContext = React.createContext<DialogContextType | null>(null)

export function DialogProvider({children}: { children: JSX.Element | JSX.Element[] }) {

    const [isLoading, setIsLoading] = useState(false)

    const [type, setType] = useState<string>()
    const [activeDialog, setActiveDialog] = useState<PendingDialog>()

    const [isTransferModalOpen, setIsTransferModalOpen] = useState(false)
    const [isIgnoreModalOpen, setIsIgnoreModalOpen] = useState(false)
    const [isSwitchProfileModalOpen, setIsSwitchProfileModalOpen] = useState(false)

    const loadDialog = async (type: string) => {
        setIsLoading(true)

        try {
            const dialog = await getPendingDialog(type)

            setActiveDialog(dialog || undefined)

        } catch (e) {
            toast.error(DEFAULT_ERROR_MESSAGE)
        } finally {
            setIsLoading(false)
        }
    }

    const onFinishDialog = async () => {

        if (!activeDialog) return

        setIsLoading(true)
        try {
            await finishDialog(activeDialog.DialogId)
            await loadDialog(type!)
        } catch (e) {
            toast.error(DEFAULT_ERROR_MESSAGE)
            setActiveDialog(undefined)
        } finally {
            setIsLoading(false)
        }
    }


    const onDialogTransferred = async (transferred: boolean) => {

        setIsTransferModalOpen(false)

        if (transferred && type) {
            await loadDialog(type)
        }
    }

    const onProfileSwitched = async (switched: boolean) => {

        setIsSwitchProfileModalOpen(false)

        if (switched && type) {
            await loadDialog(type)
        }
    }

    const onDialogIgnored = async (ignored: boolean) => {

        setIsIgnoreModalOpen(false)

        if (ignored && type) {
            await loadDialog(type)
        }
    }

    useEffect(() => {

        console.log("Type", type)
        if (!type) return

        loadDialog(type)

    }, [type])

    useEffect(() => {
        console.log("open", isTransferModalOpen)
    }, [isTransferModalOpen])


    return (
        <>
            <DialogContext.Provider value={{
                activeDialog: activeDialog,
                isLoading,
                type,
                finishDialog: onFinishDialog,
                switchProfileDialog: () => setIsSwitchProfileModalOpen(true),
                ignoreDialog: () => setIsIgnoreModalOpen(true),
                loadDialog: () => {
                    if (!type) {
                        setActiveDialog(undefined)
                        return
                    }

                    return loadDialog(type);
                },
                setType,
                transferDialog: () => setIsTransferModalOpen(true),
            }}>
                {children}
                <DialogTransferModal isOpen={isTransferModalOpen}
                                     close={onDialogTransferred}/>

                <DialogSwitchProfileModal isOpen={isSwitchProfileModalOpen}
                                          close={onProfileSwitched}/>

                <DialogIgnoreModal isOpen={isIgnoreModalOpen} close={onDialogIgnored}/>
            </DialogContext.Provider>

        </>
    )
}
