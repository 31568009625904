import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay-ts";
import {INotification} from "../../../models/notification";
import {getLatestGreetings} from "../../../services/notification.service";
import {DialogContext} from "../../../context/dialog.context";

export default function DialogGreetingsConversation() {

  const [isLoading, setIsLoading] = useState(false)
  const [greetings, setGreetings] = useState<INotification[]>([])

  const context = useContext(DialogContext)

  useEffect(() => {

    let cancel = false

    if (!context?.activeDialog?.UserId || !context?.activeDialog?.FakeId) {
      setGreetings([])
      return
    }


    const loadGreetings = async () => {

      setIsLoading(true)

      const greetings = await getLatestGreetings(context?.activeDialog?.UserId!, context?.activeDialog?.FakeId!)

      if (cancel) return

      setGreetings(greetings)
      setIsLoading(false)
    }

    loadGreetings()

    return () => {
      cancel = true
    }


  }, [context?.activeDialog?.UserId, context?.activeDialog?.FakeId])

  return (
      <LoadingOverlay spinner active={isLoading}>
        <div className={"d-flex flex-column justify-content-stretch mt-2"}>

          {greetings.length <= 0 ? "Keine Grüße vorhanden" : null}
          {greetings.map((greeting) => {

            return (
                <div key={greeting.NotificationId}>
                  <div className={"d-flex flex-row mt-2"}>
                    <div className={"d-flex flex-column flex-grow-1"}>
                      <strong>{greeting.FromUserId === context?.activeDialog?.FakeId ? "Wir" : "Kunde"}</strong>
                      <small>{greeting.Message}</small>
                    </div>
                    <div className={"ml-auto"}>
                      <small>{moment(greeting.DateCreate).format("dddd, DD.MMMM YYYY HH:mm")}</small>
                    </div>
                  </div>
                  <hr className={"w-100"}/>
                </div>
            )

          })}

        </div>
      </LoadingOverlay>
  )
}
