import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {Card, CardBody, CardHeader, Collapse, Spinner} from 'reactstrap'
import {DEFAULT_ERROR_MESSAGE} from '../../helpers/constants'
import {getInfo, getInterest} from '../../services/user.service'
import {User, UserInformation, UserInterest} from '../../models/user'
import moment from 'moment'

declare type DialogUserDetailsOptions = {
  user?: User
}

export default function DialogUserDetails(opt: DialogUserDetailsOptions) {
  const [isLoading, setIsLoading] = useState(false)
  const [information, setInformation] = useState<UserInformation>()
  const [interest, setInterest] = useState<UserInterest>()
  const [isOpen, setIsOpen] = useState(false)

  const loadDetails = async () => {
    setIsLoading(true)

    try {
      const info = await getInfo(opt.user?.UserId!)
      const _interest = await getInterest(opt.user?.UserId!)

      setIsLoading(false)
      setInformation(info)
      setInterest(_interest)
    } catch (e) {
      toast.error(DEFAULT_ERROR_MESSAGE)
    }
  }

  useEffect(() => {
    if (!opt.user?.UserId) return

    loadDetails()
  }, [opt.user?.UserId])

  if (isLoading) {
    return (
        <>
          <Card>
            <CardHeader>
              <strong>Lade Kundeninformationen</strong>
              <Spinner
                  className={'ms-2'}
                  color={'dark'}
                  size={'sm'}
              />
            </CardHeader>
          </Card>
        </>
    )
  }

  return (
      <>
        <Card>
          <CardHeader
              style={{cursor: 'pointer'}}
              className={'d-flex align-items-center'}
              onClick={() => setIsOpen(!isOpen)}
          >
            Kundeninformationen{' '}
            <i
                className={
                  isOpen
                      ? 'ms-2 ri-arrow-up-s-line'
                      : 'ms-2 ri-arrow-down-s-line'
                }
            />
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody>
              <ul className="list-group list-group-flush">
                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Alter</strong>
                  <span className="text-right">
                                    {' '}
                    {moment().diff(
                        moment(opt.user?.BirthDate),
                        'year'
                    )}{' '}
                                </span>
                </li>
                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Sucht nach</strong>
                  <span className="text-right">
                                    {' '}
                    {interest?.LookForGender ??
                        'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Möchte</strong>
                  <span className="text-right">
                                    {' '}
                    {interest?.LookForType ??
                        'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Lebe bei</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.LivingStatus ??
                        'Keine Angabe'}{' '}
                                </span>
                </li>
              </ul>

              <ul className="list-group list-group-flush mt-4">
                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Haare</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.HairColor ??
                        'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Augen</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.EyeColor ??
                        'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Größe</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.Size ?? 'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Gewicht</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.Weight ?? 'Keine Angabe'}{' '}
                                </span>
                </li>
              </ul>

              <ul className="list-group list-group-flush mt-4">
                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Single seit</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.LastRelationship ??
                        'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Beziehungsstatus</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.RelationshipStatus ??
                        'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Einkommen</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.Income ?? 'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Religion</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.Religion ??
                        'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Beruf</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.Job ?? 'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Ausbildung</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.Education ??
                        'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between">
                  <strong>Raucher</strong>
                  <span className="text-right">
                                    {' '}
                    {information?.Smoker ?? 'Keine Angabe'}{' '}
                                </span>
                </li>

                <li className="list-group-item w-100 d-flex justify-content-between flex-wrap">
                  <strong>Hobbies</strong>
                  <span className="text-right">
                                    {' '}
                    {interest?.Hobbies ?? 'Keine Angabe'}{' '}
                                </span>
                </li>
              </ul>
            </CardBody>
          </Collapse>
        </Card>
      </>
  )
}
