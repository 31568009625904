import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Box from '../../components/Common/Box'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import getModeratorStatistic from '../../services/statistic.service'
import { ModeratorStatistic } from '../../models/statistic'
import { toast } from 'react-toastify'

export default function StarterPage() {
    const [breadcrumbItems] = useState([
        { title: 'Moderation', link: '/' },
        { title: 'Dashboard', link: '/dashboard' },
    ])

    const [isLoadingStatistic, setIsLoadingStatistic] = useState(true)
    const [statistic, setStatistic] = useState<ModeratorStatistic>()

    const dispatch = useAppDispatch()
    const username = useAppSelector((state) => state.authReducer.username)

    useEffect(() => {
        console.log('I am here bruh')
    }, [dispatch])

    useEffect(() => {
        const loadStatistic = async () => {
            try {
                const statistic = await getModeratorStatistic()

                setStatistic(statistic)

                setIsLoadingStatistic(false)
            } catch (e) {
                toast.error(
                    'Beim Laden der Statistik ist ein Fehler aufgetreten.'
                )
            }
        }

        setIsLoadingStatistic(true)

        loadStatistic()
    }, [])

    return (
        <React.Fragment>
            <Row className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Dashboard"
                        breadcrumbItems={breadcrumbItems}
                    />

                    <Row>
                        <div className={'col-6 d-flex justify-content-center'}>
                            <Box
                                title={'INs'}
                                type={'number'}
                                value={statistic?.IncomingMessageCount ?? 0}
                                isLoading={isLoadingStatistic}
                            />
                        </div>
                        <div className={'col-6 d-flex justify-content-center'}>
                            <Box
                                title={'OUTs'}
                                type={'number'}
                                value={statistic?.OutgoingMessageCount ?? 0}
                                isLoading={isLoadingStatistic}
                            />
                        </div>
                    </Row>
                </Container>
            </Row>
        </React.Fragment>
    )
}
