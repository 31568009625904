import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {Spinner} from 'reactstrap'
import {DEFAULT_ERROR_MESSAGE} from '../../helpers/constants'
import {hasChargedAccount} from '../../services/user.service'

declare type UserAccountChargedOptions = {
  userId?: number
}

export default function UserAccountCharged(opt: UserAccountChargedOptions) {
  const [isLoading, setIsLoading] = useState(false)
  const [hasCharged, setHasCharged] = useState<boolean>()

  const loadHasCharged = async () => {
    setIsLoading(true)

    try {
      const charged = await hasChargedAccount(opt.userId!)

      setHasCharged(charged)
      setIsLoading(false)
    } catch (e) {
      toast.error(DEFAULT_ERROR_MESSAGE)
    }
  }

  useEffect(() => {
    if (!opt.userId) return

    loadHasCharged()
  }, [opt.userId])

  if (isLoading) {
    return (
        <>
          <Spinner className={'me-2'} color={'light'} size={'sm'}/>
        </>
    )
  }

  if (hasCharged) {
    return (
        <span title={"Hat bereits aufgeladen"}>🤑</span>
    )
  }

  return <></>
}
