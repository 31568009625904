import styled from 'styled-components'
import {useState} from 'react'
import {Badge, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import {DialogMessageInfo} from "../../models/dialog";
import DialogMessageConversation from "./conversation/DialogMessageConversation";
import DialogPictureConversation from "./conversation/DialogPictureConversation";
import DialogPresentsConversation from "./conversation/DialogPresentsConversation";
import DialogKissesConversation from "./conversation/DialogKissesConversation";
import DialogGreetingsConversation from "./conversation/DialogGreetingsConversation";

declare type DialogConversationsOptions = {
  info?: DialogMessageInfo
  // userId?: number
  // partnerId?: number
}

const TabLink = styled.a`
    &:hover {
        color: white !important;
    }
`

export default function DialogConversations(opt: DialogConversationsOptions) {
  const [activeTab, setActiveTab] = useState('messages')

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
      <div className={'d-flex flex-column w-100'}>
        <Nav tabs className={'d-flex justify-content-around w-100'}>
          <NavItem>
            <NavLink
                title={"Nachrichten"}
                className={
                    (activeTab === 'messages' ? 'active ' : '') +
                    'text-center'
                }
                onClick={() => toggle('messages')}
            >
              <div
                  className={
                    'd-flex align-items-center justify-content-center'
                  }
              >
                <div>
                  <i className={'ri-message-line'}/>
                </div>
                <div className={'ms-2'}>
                  <Badge color={'dark'}>{opt.info?.MessageReceivedCount}</Badge>
                </div>
              </div>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
                title={"Bilder"}
                className={
                    (activeTab === 'images' ? 'active ' : '') +
                    'text-center'
                }
                onClick={() => toggle('images')}
            >
              <div
                  className={
                    'd-flex align-items-center justify-content-center'
                  }
              >
                <div>
                  <i className={'ri-picture-in-picture-line'}/>
                </div>
                <div className={'ms-2'}>
                  <Badge color={'dark'}>{opt.info?.ConversationPictureCount}</Badge>
                </div>
              </div>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
                title={"Grüße"}
                className={
                    (activeTab === 'greetings' ? 'active ' : '') +
                    'text-center'
                }
                onClick={() => toggle('greetings')}
            >
              <div
                  className={
                    'd-flex align-items-center justify-content-center'
                  }
              >
                <div>
                  <i className={'ri-chat-smile-line'}/>
                </div>
                <div className={'ms-2'}>
                  <Badge color={'dark'}>{opt.info?.GreetingCount}</Badge>
                </div>
              </div>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
                title={"Küsse"}
                className={
                    (activeTab === 'kisses' ? 'active ' : '') +
                    'text-center'
                }
                onClick={() => toggle('kisses')}
            >
              <div
                  className={
                    'd-flex align-items-center justify-content-center'
                  }
              >
                <div>
                  <i
                      className={'mdi mdi-emoticon-kiss-outline'}
                  />
                </div>
                <div className={'ms-2'}>
                  <Badge color={'dark'}>{opt.info?.KissCount}</Badge>
                </div>
              </div>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
                title={"Geschenke"}
                className={
                    (activeTab === 'presents' ? 'active ' : '') +
                    'text-center'
                }
                onClick={() => toggle('presents')}
            >
              <div
                  className={
                    'd-flex align-items-center justify-content-center'
                  }
              >
                <div>
                  <i className={'ri-gift-fill'}/>
                </div>
                <div className={'ms-2'}>
                  <Badge color={'dark'}>{opt.info?.PresentCount}</Badge>
                </div>
              </div>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'messages'} className={'p-2'}>
            {activeTab === 'messages' ? <DialogMessageConversation/> : null}
          </TabPane>

          <TabPane tabId={'images'} className={'p-2'}>
            {activeTab === 'images' ? <DialogPictureConversation/> : null}
          </TabPane>

          <TabPane tabId={'presents'} className={'p-2'}>
            {activeTab === 'presents' ? <DialogPresentsConversation/> : null}
          </TabPane>

          <TabPane tabId={'kisses'} className={'p-2'}>
            {activeTab === 'kisses' ? <DialogKissesConversation/> : null}
          </TabPane>

          <TabPane tabId={'greetings'} className={'p-2'}>
            {activeTab === 'greetings' ? <DialogGreetingsConversation/> : null}
          </TabPane>
        </TabContent>
      </div>
  )
}
