import axios, {CancelToken} from "axios";
import environment from "../helpers/environment";
import {IPresent} from "../models/present";

export async function getLatestPresents(fakeId: number, userId: number): Promise<IPresent[]> {
  return (await axios.get<IPresent[]>(`${environment.API_URL}/user/${userId}/${fakeId}/presents`)).data
}

export async function getPresentPictureUrl(presentId: number, cancelToken?: CancelToken): Promise<string> {

  const pictureData = (await axios.get(`${environment.API_URL}/present/${presentId}/picture`, {
    responseType: "blob",
    cancelToken
  })).data

  return URL.createObjectURL(pictureData)
}

export async function getPresent(presentId: number, cancelToken?: CancelToken): Promise<IPresent> {
  return (await axios.get<IPresent>(`${environment.API_URL}/present/${presentId}`, {
    cancelToken
  })).data
}
