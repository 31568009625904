import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {useContext, useState} from "react";
import {DialogContext} from "../../context/dialog.context";
import LoadingOverlay from "react-loading-overlay-ts";
import {createPortal} from "react-dom";
import {ignoreDialog} from "../../services/dialog.service";
import {toast} from "react-toastify";

export type DialogIgnoreModalOptions = {
  isOpen: boolean
  close: (transferred: boolean) => void
}

const availableMinutes: Record<number, string> = {
  10: "10 Minuten",
  30: "30 Minuten",
  60: "1 Stunde",
  120: "2 Stunden",
  180: "3 Stunden",
  1440: "1 Tag",
  2880: "2 Tage",
  5760: "4 Tage",
  10080: "1 Woche",
  20160: "2 Wochen",
}


export default function DialogIgnoreModal(opt: DialogIgnoreModalOptions) {

  const context = useContext(DialogContext)
  const [minutesIgnore, setMinutesIgnore] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)

  const onIgnoreDialog = async () => {

    if (!context || !context.activeDialog || !minutesIgnore) return

    try {
      setIsLoading(true)
      await ignoreDialog(context.activeDialog.DialogId, minutesIgnore)
      opt.close(true)

      toast.success("Der Dialog wurde erfolgreich ausgesetzt!")
    } catch (e) {
      toast.error("Beim Aussetzen des Dialogs ist ein Fehler aufgetreten.")
    } finally {
      setIsLoading(false)
    }
  }

  return createPortal(
      <Modal isOpen={opt.isOpen} toggle={() => opt.close(false)}>
        <ModalHeader>
          Dialog aussetzen
        </ModalHeader>
        <ModalBody>
          <LoadingOverlay active={isLoading} spinner>
            <div className={"flex flex-row"}>
              <label className={"text-black"}>
                Dauer auswählen
              </label>
              <select className={"form-control"}
                      onChange={(e) => setMinutesIgnore(parseInt(e.currentTarget.value))}>
                {
                  Object.entries(availableMinutes).map(([key, value]) => (
                      <option key={key} value={key}>{value}</option>
                  ))
                }
              </select>

              <button
                  className={`btn btn-primary mt-3 w-100 ${minutesIgnore ? "" : "disabled"}`}
                  disabled={minutesIgnore === undefined}
                  onClick={async () => await onIgnoreDialog()}>
                Ausssetzen
              </button>
              <button className={"btn btn-danger text-white mt-3 w-100"}
                      onClick={() => opt.close(false)}>
                Abbrechen
              </button>
            </div>
          </LoadingOverlay>
        </ModalBody>
      </Modal>, document.body
  )
}
