import axios from 'axios'
import environment from '../helpers/environment'
import { ILoginResult } from '../models/auth'

export default async function login(
    username: string,
    password: string
): Promise<ILoginResult> {
    return (
        await axios.post<ILoginResult>(`${environment.API_URL}/auth/login`, {
            username,
            password,
        })
    ).data
}
