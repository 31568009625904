import React, {useContext, useState} from 'react'
import {Container, Row} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import QueueConfiguration from '../../components/Queue/QueueConfiguration'
import Dialog from '../../components/Dialog/Dialog'
import LoadingOverlay from 'react-loading-overlay-ts'
import styled from 'styled-components'
import DialogUserInfo from '../../components/Dialog/DialogUserInfo'
import {DialogContext} from "../../context/dialog.context";

const AlertBox = styled.div`
    background-color: black;
    border-radius: 20px;
    padding: 30px;
`

export default function QueuePage() {
  const [breadcrumbItems] = useState([
    {title: 'Moderation', link: '/'},
    {title: 'Warteschlange', link: '/queue'},
  ])

  const context = useContext(DialogContext)

  return (
      <React.Fragment>
        <Row className="page-content">
          <Container fluid>
            <Breadcrumbs
                title="Dashboard"
                breadcrumbItems={breadcrumbItems}
            />

            <Row>
              <div className={'col-12 d-flex justify-content-center'}>
                <QueueConfiguration/>
              </div>
            </Row>

            {!context?.type ? (
                <div className={'d-flex justify-content-center col-12'}>
                  <div
                      className={
                        'text-white fs-4 text-uppercase badge bg-warning'
                      }
                  >
                    Bitte Dialog-Art auswählen
                  </div>
                </div>
            ) : (context?.activeDialog ? (
                <Row
                    className={'col-12 d-flex flex-row flex-wrap gx-1'}
                >
                  <div className={'col-12 col-md-3'}>
                    <LoadingOverlay
                        active={context?.isLoading}
                        spinner
                    >
                      <DialogUserInfo
                          userId={context?.activeDialog?.UserId}
                          partnerId={context?.activeDialog?.FakeId}
                      />
                    </LoadingOverlay>
                  </div>
                  <div className={'col-12 col-md-6'}>
                    <LoadingOverlay
                        active={context?.isLoading}
                        spinner
                    >
                      <Dialog/>
                    </LoadingOverlay>
                  </div>
                  <div className={'col-12 col-md-3'}>
                    <LoadingOverlay
                        active={context?.isLoading}
                        spinner
                    >
                      <DialogUserInfo
                          userId={context?.activeDialog?.FakeId}
                          partnerId={context?.activeDialog?.UserId}
                      />
                    </LoadingOverlay>
                  </div>
                </Row>
            ) : (
                <Row>
                  <div className={"col-12 text-center"}>
                    Warteschlange ist leer
                  </div>
                </Row>
            ))}
          </Container>
        </Row>
      </React.Fragment>
  )
}
