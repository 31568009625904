import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {useContext, useEffect, useState} from "react";
import {DialogContext} from "../../context/dialog.context";
import getOnlineModerators from "../../services/moderator.service";
import LoadingOverlay from "react-loading-overlay-ts";
import {createPortal} from "react-dom";
import {transferDialog} from "../../services/dialog.service";
import {toast} from "react-toastify";
import {IModerator} from "../../models/moderator";

export type DialogTransferModalOptions = {
  isOpen: boolean
  close: (transferred: boolean) => void
}

export default function DialogTransferModal(opt: DialogTransferModalOptions) {

  const context = useContext(DialogContext)

  const [onlineModerators, setOnlineModerators] = useState<IModerator[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedModerator, setSelectedModerator] = useState<number>()

  useEffect(() => {

    const load = async () => {
      setIsLoading(true)
      const mods = await getOnlineModerators()
      setOnlineModerators(mods)
      if (mods.length > 0) {
        setSelectedModerator(mods[0].ModeratorId)
      }
      setIsLoading(false)
    }

    if (opt.isOpen) {
      load()
    }

  }, [opt.isOpen])

  useEffect(() => {
    console.log("Mod", selectedModerator)
  }, [selectedModerator])

  const onTransferDialog = async () => {

    if (!context || !context.activeDialog || !selectedModerator) {
      console.log({
        context,
        selectedModerator
      })
      return
    }

    try {
      setIsLoading(true)
      await transferDialog(context.activeDialog.DialogId, selectedModerator)
      opt.close(true)

      toast.success("Der Dialog wurde erfolgreich übergeben!")
    } catch (e) {
      toast.error("Beim Übergeben des Dialogs ist ein Fehler aufgetreten.")
    } finally {
      setIsLoading(false)
    }
  }

  return createPortal(
      <Modal isOpen={opt.isOpen} toggle={() => opt.close(false)}>
        <ModalHeader>
          Dialog übergeben
        </ModalHeader>
        <ModalBody>
          <LoadingOverlay active={isLoading} spinner>
            <div className={"flex flex-row"}>
              <label className={"text-black"}>
                Moderator auswählen
              </label>
              <select className={"form-control"}
                      onChange={(e) => setSelectedModerator(parseInt(e.currentTarget.value))}>
                {onlineModerators?.map((mod, index) => {
                  return (
                      <option value={mod.ModeratorId}>{mod.Username}</option>
                  )
                })}
              </select>

              <button
                  className={`btn btn-primary mt-3 w-100 ${selectedModerator ? "" : "disabled"}`}
                  disabled={selectedModerator === undefined}
                  onClick={async () => await onTransferDialog()}>
                Übergeben
              </button>
              <button className={"btn btn-danger text-white mt-3 w-100"}
                      onClick={() => opt.close(false)}>
                Abbrechen
              </button>
            </div>
          </LoadingOverlay>
        </ModalBody>
      </Modal>, document.body
  )
}
