import React, {useContext, useEffect, useState} from 'react'
import {DialogMessageInfo} from '../../models/dialog'
import {DEFAULT_ERROR_MESSAGE} from '../../helpers/constants'
import {toast} from 'react-toastify'
import {getDialogMessageInfo} from '../../services/dialog.service'
import moment from 'moment'
import styled from 'styled-components'
import DialogConversations from './DialogConversations'
import SendMessage from "./DialogSendMessage";
import {DialogContext} from "../../context/dialog.context";
import DialogToolbar from "./DialogToolbar";

const DividerLine = styled.div`
  height: 2px;
  width: 100%;
  background-color: #f1f5f7;
`

export default function DialogMessage() {
  const [isLoadingMessageInfo, setIsLoadingMessageInfo] = useState(false)
  const [messageInfo, setMessageInfo] = useState<DialogMessageInfo>()

  const [firstMessageReceived, setFirstMessageReceived] = useState<number>()

  const context = useContext(DialogContext)

  useEffect(() => {
    loadMessageInfo()
  }, [context?.activeDialog])


  useEffect(() => {
    if (!messageInfo) return

    const date = moment(messageInfo.DateFirstMessage)
    const diffInDays = moment().diff(date, 'days')
    setFirstMessageReceived(diffInDays)
  }, [messageInfo])

  const loadMessageInfo = async () => {

    if (!context?.activeDialog) {
      setMessageInfo(undefined)
      return
    }

    setIsLoadingMessageInfo(true)

    try {
      const info = await getDialogMessageInfo(
          context.activeDialog.DialogId,
          context.activeDialog.UserId,
          context.activeDialog.FakeId
      )

      setMessageInfo(info)
    } catch (e) {
      toast.error(DEFAULT_ERROR_MESSAGE)
    } finally {
      setIsLoadingMessageInfo(false)
    }
  }

  return (
      <>
        <div
            className={
              'd-flex flex-row justify-content-between flex-wrap w-100'
            }
        >
          {firstMessageReceived == 0 ? (
              <strong className={'text-danger mx-auto'}>
                Noch nie eine Nachricht erhalten
              </strong>
          ) : (
              <>
                <div className={''}>
                  {firstMessageReceived &&
                  firstMessageReceived > 0 ? (
                      <span>
                                    Erste Nachricht vor {firstMessageReceived}{' '}
                        Tage(n) erhalten
                                </span>
                  ) : (
                      <span>
                                    Noch <strong>nie</strong> eine Nachricht
                                    erhalten
                                </span>
                  )}
                </div>
                <div>
                  Insgesamt schon{' '}
                  <strong>
                    {messageInfo?.MessageReceivedCount ?? 0}{' '}
                    Nachrichten erhalten
                  </strong>
                </div>
              </>
          )}
        </div>
        <DividerLine className={'mt-3 mb-3'}/>

        <DialogToolbar/>
        
        <DividerLine className={'mt-3 mb-3'}/>

        {messageInfo ?
            <SendMessage info={messageInfo}
            /> : null}

        <div className={'mt-4 justify-content-center d-flex'}>
          <DialogConversations info={messageInfo}
          />
        </div>
      </>
  )
}
