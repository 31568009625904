import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'
import { DEFAULT_ERROR_MESSAGE } from '../../helpers/constants'
import { isUserVerified } from '../../services/user.service'

declare type UserVerifiedOptions = {
    userId?: number
}

export default function UserVerified(opt: UserVerifiedOptions) {
    const [isLoading, setIsLoading] = useState(false)
    const [isVerified, setIsVerified] = useState<boolean>()

    const loadVerified = async () => {
        setIsLoading(true)

        try {
            const verified = await isUserVerified(opt.userId!)

            setIsVerified(verified)
            setIsLoading(false)
        } catch (e) {
            toast.error(DEFAULT_ERROR_MESSAGE)
        }
    }

    useEffect(() => {
        if (!opt.userId) return

        loadVerified()
    }, [opt.userId])

    if (isLoading) {
        return (
            <>
                <Spinner className={'me-2'} color={'light'} size={'sm'} />
            </>
        )
    }

    if (isVerified) {
        return (
            <i
                className={'ms-2 ri-star-fill'}
                style={{ color: 'yellow' }}
                title={'Verifiziert'}
            ></i>
        )
    }

    return <></>
}
