import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'
import { DEFAULT_ERROR_MESSAGE } from '../../helpers/constants'
import { getCoinCount } from '../../services/user.service'

declare type CoinCountOptions = {
    userId?: number
}

export default function CoinCount(opt: CoinCountOptions) {
    const [isLoading, setIsLoading] = useState(false)
    const [coinCount, setCoinCount] = useState<string>()

    const loadCoinCount = async () => {
        setIsLoading(true)

        try {
            const count = await getCoinCount(opt.userId!)

            setCoinCount(count)
            setIsLoading(false)
        } catch (e) {
            toast.error(DEFAULT_ERROR_MESSAGE)
        }
    }

    useEffect(() => {
        if (!opt.userId) return

        loadCoinCount()
    }, [opt.userId])

    if (isLoading) {
        return (
            <>
                <Spinner className={'me-2'} color={'dark'} size={'sm'} />
                <strong>Flirt Coins</strong>
            </>
        )
    }

    return (
        <>
            <span className={'me-2'}>{coinCount}</span>
            <strong>Flirt Coins</strong>
        </>
    )
}
