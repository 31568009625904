import {useEffect, useState} from "react";
import {Spinner} from "reactstrap";
import {toast} from "react-toastify";
import {DEFAULT_ERROR_MESSAGE} from "../../helpers/constants";
import {getModeratorDialogs} from "../../services/moderator.service";

export type ModeratorDialogOptions = {
  isOpen: boolean
}

export default function ModeratorDialogs(opt: ModeratorDialogOptions) {

  const [isLoading, setIsLoading] = useState(true)
  const [dialogs, setDialogs] = useState<Record<string, number>>()

  useEffect(() => {

    if (!opt.isOpen) {
      setDialogs(undefined)
      setIsLoading(true)
      return
    }

    const loadDialogs = async () => {
      setIsLoading(true)

      try {
        const _dialogs = await getModeratorDialogs()

        setDialogs(_dialogs)
        setIsLoading(false)
      } catch (e) {
        toast.error(DEFAULT_ERROR_MESSAGE)
      }
    }

    loadDialogs()


  }, [opt.isOpen])

  if (!opt.isOpen) {
    return (
        <></>
    )
  }

  if (isLoading) {
    return (
        <div className={"w-full text-center mt-4"}>
          <Spinner size={"md"}/>
        </div>
    )
  }

  if (!dialogs) {
    return (
        <>Keine Moderatoren online</>
    )
  }

  return (
      <div className={"mt-4"}>
        <h5>Dialoge anderer Moderatoren</h5>
        <div className={"d-flex flex-column gx-4"}>
          {
            Object.entries(dialogs).map(([moderator, count]) => {
              return (
                  <div className={"d-flex flex-row gap-4"}>
                    {moderator}: {count}
                  </div>
              )
            })
          }
        </div>
      </div>
  )
}
