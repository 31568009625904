import axios from 'axios'
import environment from '../helpers/environment'
import {IModerator} from "../models/moderator";

export default async function getOnlineModerators(): Promise<IModerator[]> {
  return (
      await axios.get<IModerator[]>(`${environment.API_URL}/moderator/online`)
  ).data
}

export async function getModeratorDialogs(): Promise<Record<string, number>> {
  return (
      await axios.get<Record<string, number>>(`${environment.API_URL}/moderator/dialogs`)
  ).data
}
