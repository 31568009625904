import React, {useContext, useEffect, useState} from "react";
import {IMessage} from "../../../models/message";
import {getPictureMessages} from "../../../services/message.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DialogPicture from "../DialogPicture";
import {DialogContext} from "../../../context/dialog.context";

export default function DialogPictureConversation() {

  const [isLoading, setIsLoading] = useState(false)
  const [pictures, setPictures] = useState<IMessage[]>([])

  const context = useContext(DialogContext)

  useEffect(() => {

    let cancel = false

    if (!context?.activeDialog?.UserId || !context?.activeDialog?.FakeId) {
      setPictures([])
      return
    }


    const loadPictureMessages = async () => {

      setIsLoading(true)

      const pictureMessages = await getPictureMessages(context?.activeDialog?.UserId!, context?.activeDialog?.FakeId!)

      if (cancel) return

      setPictures(pictureMessages)
      setIsLoading(false)
    }

    loadPictureMessages()

    return () => {
      cancel = true
    }

  }, [context?.activeDialog?.UserId, context?.activeDialog?.FakeId])

  return (
      <LoadingOverlay spinner active={isLoading}>
        <div className={"d-flex flex-row justify-content-stretch"}>

          <div className={"col"}>
            <div className={"pt-2 text-center"}>
              <h4 className={"fs-6"}>Empfangen</h4>
              <hr/>
            </div>
            <div className={"d-flex flex-row flex-wrap"}>
              {
                pictures.filter(x => x.ReceiverUserId === context?.activeDialog?.FakeId).length <= 0 ? "Keine Bilder empfangen" : null
              }
              {pictures.filter(x => x.ReceiverUserId === context?.activeDialog?.FakeId).map((msg) => {
                return (
                    <div className={"p-2"} key={msg.MessageId}>
                      <DialogPicture messageId={msg.MessageId}/>
                    </div>
                )
              })}
            </div>
          </div>


          <div className={"col"}>
            <div className={"pt-2  text-center"}>
              <h4 className={"fs-6"}>Gesendet</h4>
              <hr/>
            </div>
            <div className={"d-flex flex-row flex-wrap"}>

              {
                pictures.filter(x => x.SenderUserId === context?.activeDialog?.FakeId).length <= 0 ? "Keine Bilder gesendet" : null
              }
              {pictures.filter(x => x.SenderUserId === context?.activeDialog?.FakeId).map((msg) => {
                return (
                    <div className={"p-2"} key={msg.MessageId}>
                      <DialogPicture messageId={msg.MessageId}/>
                    </div>
                )
              })}
            </div>
          </div>

        </div>
      </LoadingOverlay>
  )
}
