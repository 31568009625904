import axios from "axios";
import environment from "../helpers/environment";
import {INotification} from "../models/notification";

export async function getLatestKisses(fakeId: number, userId: number): Promise<INotification[]> {
  return (await axios.get<INotification[]>(`${environment.API_URL}/user/${userId}/${fakeId}/kisses`)).data
}

export async function getLatestGreetings(fakeId: number, userId: number): Promise<INotification[]> {
  return (await axios.get<INotification[]>(`${environment.API_URL}/user/${userId}/${fakeId}/greetings`)).data
}
