import axios, {CancelToken} from "axios";
import environment from "../helpers/environment";
import {IMessage, IMessageContent} from "../models/message";

export async function getMessages(userId: number, partnerId: number): Promise<IMessage[]> {
  return (await axios.get<IMessage[]>(`${environment.API_URL}/user/${userId}/${partnerId}/messages`)).data
}

export async function getPictureMessages(userId: number, partnerId: number): Promise<IMessage[]> {
  return (await axios.get<IMessage[]>(`${environment.API_URL}/user/${userId}/${partnerId}/picture-messages`)).data
}

export async function getMessagePictureUrl(messageId: number): Promise<string> {

  const pictureData = (await axios.get(`${environment.API_URL}/message/${messageId}/picture`, {responseType: "blob"})).data

  return URL.createObjectURL(pictureData)
}

export async function getMessageContent(messageId: number, cancelToken?: CancelToken): Promise<IMessageContent> {
  return (await axios.get<IMessageContent>(`${environment.API_URL}/message/${messageId}`, {
    cancelToken
  })).data
}

export async function getLatestMessages(cancelToken?: CancelToken): Promise<IMessage[]> {
  return (await axios.get<IMessage[]>(`${environment.API_URL}/message/latest-messages`, {
    cancelToken
  })).data
}

export async function getPairedMessageFrom(messageId: number, cancelToken?: CancelToken): Promise<IMessage | null> {
  return (await axios.get<IMessage | null>(`${environment.API_URL}/message/${messageId}/pair`, {
    cancelToken
  })).data
}

export async function sendMessage(senderUserId: number, receiverUserId: number, message: string | null, image: File | null): Promise<void> {
  const formData = new FormData()
  if (!!message) {
    formData.append("message", message)
  }
  if (!!image) {
    formData.append("image", image)
  }

  await axios.post(`${environment.API_URL}/message/send/${senderUserId}/${receiverUserId}`, formData)
}

export async function finishDialog(dialogId: number): Promise<void> {
  return await axios.post(`${environment.API_URL}/dialog/${dialogId}/finish`)
}
