import React, {useEffect, useState} from "react"
import {Spinner} from "reactstrap";
import axios from "axios";
import PresentPicture from "./PresentPicture";
import {getPresent} from "../../../services/present.service";
import {IPresent} from "../../../models/present";

interface IPresentOptions {
  presentId: number
}

export default function Present(opt: IPresentOptions) {

  const [present, setPresent] = useState<IPresent>()

  useEffect(() => {

    let cancelToken = axios.CancelToken.source()

    const loadPresent = async function (presentId: number) {
      const _present = await getPresent(presentId, cancelToken.token)

      setPresent(_present)
    }

    loadPresent(opt.presentId)

    return () => {
      cancelToken.cancel("Component unmounted.")
    }
  }, [opt.presentId])

  if (present) {
    return (
        <div className={"d-flex flex-column"}>
          <div className={"text-center"}>
            <PresentPicture presentId={opt.presentId}/>
          </div>
          <div>
            <small>{present.Name} ({present.Costs.toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR"
            })})</small>
          </div>
        </div>
    )
  }

  return (
      <Spinner size={"sm"}/>
  )
}
