import React, {useContext, useEffect, useState} from "react";
import {IMessage} from "../../../models/message";
import {getMessages} from "../../../services/message.service";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay-ts";
import {Emojione} from "react-emoji-render";
import {DialogContext} from "../../../context/dialog.context";

export default function DialogMessageConversation() {

  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState<IMessage[]>([])

  const context = useContext(DialogContext)

  useEffect(() => {

    let cancel = false

    if (!context?.activeDialog?.UserId || !context?.activeDialog?.FakeId) {
      setMessages([])
      return
    }


    const loadMessages = async () => {

      setIsLoading(true)

      const messages = await getMessages(context?.activeDialog?.UserId!, context?.activeDialog?.FakeId!)

      if (cancel) return

      setMessages(messages)
      setIsLoading(false)
    }

    loadMessages()

    return () => {
      cancel = true
    }


  }, [context?.activeDialog?.UserId, context?.activeDialog?.FakeId])

  return (
      <LoadingOverlay spinner active={isLoading}>
        <div className={"d-flex flex-column justify-content-stretch mt-2"}>

          {messages.length <= 0 ? "Keine Text-Nachrichten vorhanden" : null}
          {messages.map((msg) => {

            return (
                <div key={msg.MessageId}>
                  <div className={"d-flex flex-md-row flex-column mt-2"}>
                    <div className={"d-flex flex-column flex-grow-1"}>
                      <strong>{msg.SenderUserId === context?.activeDialog?.FakeId ? "Wir" : "Kunde"}</strong>
                      <small>
                        <Emojione text={msg.MessageContent?.Content}/></small>
                    </div>
                    <div className={"ml-auto"}>
                      <small>{moment(msg.DateCreate).format("dddd, DD.MMMM YYYY HH:mm")}</small>
                    </div>
                  </div>
                  <hr className={"w-100"}/>
                </div>
            )

          })}

        </div>
      </LoadingOverlay>
  )
}
