import {Card, CardBody, CardHeader} from 'reactstrap'
import React, {useContext, useEffect, useState} from 'react'
import {DialogInfo} from '../../models/dialog'
import {DEFAULT_ERROR_MESSAGE} from '../../helpers/constants'
import {toast} from 'react-toastify'
import {getDialogInfo} from '../../services/dialog.service'
import LoadingOverlay from 'react-loading-overlay-ts'
import DialogMessage from './DialogMessage'
import {DialogContext} from "../../context/dialog.context";

export default function Dialog() {
  const [dialogInfo, setDialogInfo] = useState<DialogInfo>()
  const [isLoadingInfo, setIsLoadingInfo] = useState(false)


  const context = useContext(DialogContext)

  useEffect(() => {

    if (!context?.activeDialog) return

    loadInfo(context.activeDialog.DialogId)
  }, [context?.activeDialog])

  const loadInfo = async (dialogId: number) => {
    try {
      setIsLoadingInfo(true)
      const info = await getDialogInfo(dialogId)

      setDialogInfo(info)
      console.log(info)
    } catch (e) {
      toast.error(DEFAULT_ERROR_MESSAGE)
    } finally {
      setIsLoadingInfo(false)
    }
  }

  return (
      <div className={'w-100'}>
        <Card className={'w-100'}>
          <CardHeader
              className={
                'd-flex justify-content-between bg-light text-dark'
              }
          >
            <div className={'d-flex flex-column'}>
              <div className={'fw-bolder text-uppercase'}>
                Beschreibung
              </div>
              <LoadingOverlay spinner active={isLoadingInfo}>
                            <span className={'fw-lighter fs-6'}>
                                {dialogInfo?.Description}
                            </span>
              </LoadingOverlay>
            </div>

            <div className={'d-flex flex-column'}>
              <div className={'fw-bolder text-uppercase'}>Domain</div>
              <LoadingOverlay spinner active={isLoadingInfo}>
                            <span className={'fw-lighter fs-6'}>
                                {dialogInfo?.Domain}
                            </span>
              </LoadingOverlay>
            </div>
          </CardHeader>
          <CardBody className={''}>
            {context?.activeDialog ? (
                <DialogMessage
                />
            ) : (
                <></>
            )}
          </CardBody>
        </Card>
      </div>
  )
}
