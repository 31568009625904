import React, {useEffect, useState} from "react"
import {Spinner} from "reactstrap";
import axios from "axios";
import {User} from "../../models/user";
import {getUserById} from "../../services/user.service";

interface ICustomerOptions {
  userId: number
}

export default function UserName(opt: ICustomerOptions) {

  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false)

  const [customer, setCustomer] = useState<User>()

  useEffect(() => {

    let cancelToken = axios.CancelToken.source()

    const loadCustomer = async () => {
      try {
        const user = await getUserById(opt.userId, cancelToken.token)
        setCustomer(user)
      } catch (e) {

      } finally {
        setIsLoadingCustomer(false)
      }
    }

    loadCustomer()

    return () => {
      cancelToken.cancel("Component unmounted.")
    }

  }, [])

  if (isLoadingCustomer) {
    return (
        <>
          <Spinner size={"sm"}/>
        </>
    )
  }

  return (
      <div className={"d-flex flex-wrap"}>
        <div>
          {customer?.Username}
        </div>
      </div>
  )
}
