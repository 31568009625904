import React, {useContext, useEffect, useState} from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import {getLatestPresents} from "../../../services/present.service";
import {IPresent} from "../../../models/present";
import Present from "../../Common/Present/Present";
import {DialogContext} from "../../../context/dialog.context";

export default function DialogPresentsConversation() {

  const [isLoading, setIsLoading] = useState(false)
  const [sentPresents, setSentPresents] = useState<IPresent[]>([])
  const [receivedPresents, setReceivedPresents] = useState<IPresent[]>([])

  const context = useContext(DialogContext)

  useEffect(() => {

    let cancel = false

    if (!context?.activeDialog?.UserId || !context?.activeDialog?.FakeId) {
      setSentPresents([])
      setReceivedPresents([])
      return
    }


    const loadPresents = async () => {

      setIsLoading(true)

      const receivedPresents = await getLatestPresents(context?.activeDialog?.UserId!, context?.activeDialog?.FakeId!)
      const sentPresents = await getLatestPresents(context?.activeDialog?.UserId!, context?.activeDialog?.FakeId!)

      if (cancel) return

      setSentPresents(sentPresents)
      setReceivedPresents(receivedPresents)

      setIsLoading(false)
    }

    loadPresents()

    return () => {
      cancel = true
    }

  }, [context?.activeDialog?.UserId, context?.activeDialog?.FakeId])

  return (
      <LoadingOverlay spinner active={isLoading}>
        <div className={"d-flex flex-row justify-content-stretch"}>

          <div className={"col"}>
            <div className={"pt-2 text-center"}>
              <h4 className={"fs-6"}>Empfangen</h4>
              <hr/>
            </div>
            <div className={"d-flex flex-row flex-wrap"}>

              {receivedPresents.length <= 0 ? "Keine Geschenke erhalten" : null}
              {receivedPresents.map((present) => {
                return (
                    <div className={"p-2"}>
                      <Present presentId={present.PresentId} key={present.PresentId}/>
                    </div>
                )
              })}
            </div>
          </div>


          <div className={"col"}>
            <div className={"pt-2 text-center"}>
              <h4 className={"fs-6"}>Gesendet</h4>
              <hr/>
            </div>
            <div className={"d-flex flex-row flex-wrap"}>
              {sentPresents.length <= 0 ? "Keine Geschenke versandt" : null}
              {sentPresents.map((present) => {
                return (
                    <div className={"p-2"}>
                      <Present presentId={present.PresentId} key={present.PresentId}/>
                    </div>
                )
              })}
            </div>
          </div>

        </div>
      </LoadingOverlay>
  )
}
