import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, AppThunk } from '../../app/store'

export interface ILoginState {
    isCheckingLogin: boolean
    isLoggedIn: boolean
    token?: string
    username?: string
}

const initialState: ILoginState = {
    isCheckingLogin: false,
    isLoggedIn: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        checkLogin: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.isCheckingLogin = true
        },
        loginUnsuccess: (state) => {
            state.isCheckingLogin = false
            state.isLoggedIn = false
            state.username = undefined
            state.token = undefined
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        loginSuccess: {
            reducer: (
                state,
                action: PayloadAction<{ username: string; token: string }>
            ) => {
                state.isCheckingLogin = false
                state.isLoggedIn = true
                state.username = action.payload.username
                state.token = action.payload.token
            },
            prepare: (username: string, token: string) => ({
                payload: {
                    username,
                    token,
                },
            }),
        },
        logout: (state) => {
            state.isCheckingLogin = initialState.isCheckingLogin
            state.isLoggedIn = initialState.isLoggedIn
            state.token = undefined
            state.username = undefined
        },
    },
})

export const { checkLogin, loginUnsuccess, loginSuccess, logout } =
    authSlice.actions

export default authSlice.reducer
