import React, {useMemo} from 'react'
import {BrowserRouter as Router, Switch, withRouter} from 'react-router-dom'

import 'react-toastify/dist/ReactToastify.css'
import 'react-bootstrap-typeahead/css/Typeahead.min.css'

// Import Routes
import {authProtectedRoutes, publicRoutes} from './routes/'
import AppRoute from './routes/route'

// layouts
// Import scss
import './assets/scss/theme.scss'
import axios, {AxiosError} from 'axios'
import {ToastContainer} from 'react-toastify'
import {useAppDispatch, useAppSelector} from './app/hooks'
import {logout} from './store/auth'
import HorizontalLayout from './components/HorizontalLayout'
import {DialogProvider} from "./context/dialog.context";

function App() {
  const dispatch = useAppDispatch()
  const token = useAppSelector((state) => state.authReducer.token)

  useMemo(() => {
    axios.interceptors.request.use((request) => {
      if (token) {
        if (request.headers) {
          request.headers['Authorization'] = 'Bearer ' + token
        } else {
          request.headers = {'Authorization': 'Bearer ' + token}
        }
      }

      return request
    })
  }, [token])

  axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          dispatch(logout())
          return Promise.reject(error)
        } else {
          return Promise.reject(error)
        }
      }
  )

  console.log('Route changed')

  return (

      <DialogProvider>
        <React.Fragment>
          <Router>
            <HorizontalLayout>
              <Switch>
                {publicRoutes.map((route, idx) => (
                    <AppRoute
                        path={route.path}
                        authenticationPath={'/login'}
                        authLayout={true}
                        component={route.component}
                        key={idx}
                        isProtected={false}
                    />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                    <AppRoute
                        path={route.path}
                        authenticationPath={'/login'}
                        authLayout={false}
                        component={route.component}
                        key={idx}
                        isProtected={true}
                    />
                ))}
              </Switch>

              <ToastContainer/>
            </HorizontalLayout>
          </Router>
        </React.Fragment>
      </DialogProvider>
  )
}

export default withRouter(App)
