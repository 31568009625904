import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import LoadingOverlay from 'react-loading-overlay-ts'
import styled from 'styled-components'
import { getProfilePicture } from '../../services/user.service'
import { DEFAULT_ERROR_MESSAGE } from '../../helpers/constants'

declare type ProfilePictureOptions = {
    userId?: number
}

const RoundedImage = styled.img`
    border-radius: 50%;
    height: 150px;
    width: 150px;
    object-fit: cover;
`

export default function ProfilePicture(opt: ProfilePictureOptions) {
    const [isLoading, setIsLoading] = useState(false)
    const [profilePicture, setProfilePicture] = useState<string>()

    const loadProfilePicture = async () => {
        setIsLoading(true)

        try {
            const picture = await getProfilePicture(opt.userId!)

            setProfilePicture(picture)
            setIsLoading(false)
        } catch (e) {
            toast.error(DEFAULT_ERROR_MESSAGE)
        }
    }

    useEffect(() => {
        if (!opt.userId) return

        loadProfilePicture()
    }, [opt.userId])

    if (isLoading) {
        return (
            <div className={'p-4'}>
                <LoadingOverlay active spinner></LoadingOverlay>
            </div>
        )
    }

    return <RoundedImage src={profilePicture}></RoundedImage>
}
