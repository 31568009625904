import React, {useEffect, useState} from "react"
import {Spinner} from "reactstrap";
import {getMessagePictureUrl} from "../../services/message.service";

interface IDialogPictureOptions {
  messageId: number
}

export default function DialogPicture(opt: IDialogPictureOptions) {

  const [pictureUrl, setPictureUrl] = useState<string>()

  useEffect(() => {

    let cancel = false

    const loadImage = async function () {
      const imageUrl = await getMessagePictureUrl(opt.messageId)

      if (cancel) return
      setPictureUrl(imageUrl)
    }

    loadImage()

    return () => {
      cancel = true
    }
  }, [opt.messageId])

  return (
      <>
        {pictureUrl ? <img src={pictureUrl} height={80} alt={"Dialog Bild"}/> :
            <Spinner size={"sm"}/>}
      </>
  )
}
