import React, {useEffect, useState} from 'react'
import {User} from '../../models/user'
import {toast} from 'react-toastify'
import {Card, CardBody, CardHeader} from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay-ts'

import {getUserById} from '../../services/user.service'
import ReactCountryFlag from 'react-country-flag'
import ProfilePicture from '../User/ProfilePicture'
import CoinCount from '../User/CoinCount'
import DialogNotes from './DialogNotes'
import UserVerified from '../User/UserVerified'
import DialogUserDetails from './DialogUserDetails'
import {useAppDispatch} from "../../app/hooks";
import {logout} from "../../store/auth";
import {useHistory} from "react-router-dom";
import UserAccountCharged from "../User/UserAccountCharged";

declare type UserOptions = {
  userId?: number
  partnerId?: number
}

export default function DialogUserInfo(opt: UserOptions) {
  const [isLoading, setIsLoading] = useState<boolean>()
  const [user, setUser] = useState<User>()

  const dispatch = useAppDispatch()
  const history = useHistory()

  const loadUser = async () => {
    try {
      setIsLoading(true)
      const _usr = await getUserById(opt.userId!)

      setUser(_usr)
      setIsLoading(false)
    } catch (e) {
      toast.error('Fehler beim Laden der Benutzerinformationen')
      dispatch(logout())
      history.push('/logout')
    }
  }

  useEffect(() => {
    if (!opt.userId) return

    loadUser()
  }, [opt.userId])

  if (!user || isLoading) {
    return (
        <div className={'w-100'}>
          <Card className={'w-100'}>
            <CardHeader
                className={
                  'd-flex justify-content-between bg-secondary text-white'
                }
            >
              <div className={'d-flex flex-column text-center'}>
                <div className={'fw-bolder text-uppercase'}>
                  Lade Benutzerdaten ...
                </div>
                <LoadingOverlay
                    spinner
                    active={isLoading}
                ></LoadingOverlay>
              </div>
            </CardHeader>
            <CardBody className={''}></CardBody>
          </Card>
        </div>
    )
  }

  const femaleColor = '#CC6594'
  const maleColor = '#347DC1'

  return (
      <div className={'w-100'}>
        <Card className={'w-100'}>
          <CardHeader
              className={'d-flex justify-content-between text-white'}
              style={{
                backgroundColor:
                    user.Gender === 'male' ? maleColor : femaleColor,
              }}
          >
            <div
                className={
                  'd-flex flex-column text-center align-items-center justify-content-center mx-auto'
                }
            >
              <div
                  className={
                    'fw-bolder d-flex flex-column align-items-center'
                  }
              >
                <div className={'fw-bolder text-uppercase fs-6'}>
                  {`${user.Username}`}
                  <i
                      className={`ri-${
                          user.Gender === 'male' ? 'men' : 'women'
                      }-line ms-2`}
                  ></i>
                  <UserVerified userId={user.UserId}/>
                  <UserAccountCharged userId={user.UserId}/>
                </div>
                <div className={'tw-lighter'}>
                  {user.Firstname} {user.Lastname}
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody className={'d-flex flex-column'}>
            <div className={'mx-auto'}>
              <ProfilePicture userId={opt.userId}/>
            </div>

            <div className={'mx-auto mt-2'}>
                        <span>
                            {user.City}{' '}
                          <ReactCountryFlag
                              countryCode={user.Country}
                              svg
                              className={'ms-2'}
                              style={{
                                width: '1em',
                                height: '1em',
                              }}
                              title="US"
                          />
                        </span>
            </div>

            <div className={'mx-auto mt-2'}>
              <CoinCount userId={user.UserId}/>
            </div>

            <div className={'w-100 mt-2'}>
              <DialogNotes
                  userId={user.UserId}
                  partnerId={opt.partnerId}
              />
            </div>

            <div className={'w-100 mt-4'}>
              <DialogUserDetails user={user}/>
            </div>
          </CardBody>
        </Card>
      </div>
  )
}
